<template>
  <Row class="popup-add-object__info">
    <Column>
      <Icon class="popup-add-object__info-icon"
            :height="30"
            :width="30"
            viewport="0 0 40 40"
            xlink="info"/>
    </Column>
    <Column>
      <p class="popup-add-object__info-text">{{text}}</p>
    </Column>
  </Row>
</template>

<script>
export default {
  name: 'Info',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
